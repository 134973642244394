::placeholder {
  color: #ccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc;
}

h1 {
  color: gray;
  display: inline;
  font-size: 16px !important;
}

#content {
  width: 100%;
  height: 100px;
  min-height: 30vh;
  padding: 10px;
  outline: none;
  resize: vertical;
  display: block;
  margin-top: 20px;
  background: #fff;
  border: 1px solid #ccc;
  border-top: 3px solid #ccc;
}

.char-props {
  color: gray;
  margin-bottom: 20px;
}

.btn {
  margin: 5px;
}

.btn-continer {
  text-align: center;
}

.desc {
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.copied-success-msg {
  color: #28a745;
  margin-top: 6px;
  float: right;
}

.copied-success-msg img {
  width: 20px;
}
